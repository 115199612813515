.validation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.validation-modal {
  position: relative;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  outline: none;
}

.validation-modal-content {
  display: flex;
  flex-direction: column;
}

.validation-modal-header {
  margin: 0;
  padding: 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  color: #2d3748;
}

.validation-modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #718096;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  transition: color 0.2s ease;
}

.validation-modal-close:hover {
  color: #2d3748;
}

.validation-modal-body {
  padding: 20px;
  max-height: 60vh;
  overflow-y: auto;
  background-color: white;
}

.validation-error-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  padding: 12px;
  background-color: #fef2f2;
  border-radius: 6px;
  color: #dc2626;
}

.validation-error-item.critical-error {
  background-color: #fff5f5;
  border-left: 4px solid #fc8181;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(252, 129, 129, 0.1);
}

.error-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #dc2626;
  color: white;
  border-radius: 50%;
  margin-right: 12px;
  font-weight: bold;
}

.validation-error-item.critical-error .error-icon {
  background-color: #fc8181;
  width: 28px;
  height: 28px;
  min-width: 28px;
  font-size: 1.1em;
}

.validation-error-item.critical-error span {
  color: #c53030;
  font-weight: 500;
  font-size: 1.05em;
  line-height: 1.4;
}

.validation-modal-footer {
  padding: 20px;
  border-top: 1px solid #e9ecef;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
}

.validation-modal-button {
  padding: 8px 16px;
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  transition: background-color 0.2s ease;
}

.validation-modal-button:hover {
  background-color: #2c5282;
}

.redirect-message {
  color: #4a5568;
  font-style: italic;
  text-align: center;
  padding: 0.5rem;
  width: 100%;
  animation: pulse 1.5s infinite;
  font-size: 0.95rem;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

/* Mobile responsiveness */
@media (max-width: 640px) {
  .validation-modal {
    width: 95%;
    margin: 10px;
  }

  .validation-modal-header {
    padding: 15px;
    font-size: 1.1rem;
  }

  .validation-modal-body {
    padding: 15px;
    max-height: 70vh;
  }

  .validation-error-item {
    padding: 10px;
    font-size: 0.9rem;
  }

  .error-icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-right: 10px;
  }

  .validation-modal-footer {
    padding: 15px;
  }

  .validation-modal-button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}
