/* Header style for tabs */
.mg-tabs__header {
    background-color: #f6f6f7;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 5px #5887f7;
    font-size: 16px;
    gap: 8px;
    justify-content: space-between;
}

/* Tab styling */
.mg-tabs__header span {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.1s ease;
    text-align: center;
    width: 50%;
}

/* Active tab styling */
.mg-tabs__header span.mg-tabs__header-active {
    font-weight: bold;
    background-color: #0f4bb0;
    color: white;
}

/* Hover state for non-active tabs */
.mg-tabs__header span:not(.mg-tabs__header-active):hover {
    background-color: ghostwhite;
}

/* Container style */
.mg-tabs__container {
    border-top: 2px solid black;
}