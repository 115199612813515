/* NotificationForm.css */
.notification-form {
  max-width: 600px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 40px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-form form {
  width: 100%;
  background-color: white;
  padding: 35px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: #2c3e50;
  font-size: 15px;
}

.form-group select {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: white;
  font-size: 15px;
  color: #2c3e50;
  transition: all 0.3s ease;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 15px;
}

.form-group select:hover {
  border-color: #cbd5e0;
}

.form-group textarea {
  width: 100%;
  padding: 12px 15px;
  border: 2px solid #e8e8e8;
  border-radius: 8px;
  background-color: white;
  font-size: 15px;
  color: #2c3e50;
  resize: vertical;
  min-height: 120px;
  transition: all 0.3s ease;
  font-family: inherit;
}

.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
}

.submit-button {
  background-color: #3498db;
  color: white;
  padding: 14px 28px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #2980b9;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(52, 152, 219, 0.15);
}

.submit-button:active {
  transform: translateY(0);
}

/* Add some responsive styling */
@media (max-width: 768px) {
  .notification-form {
    padding: 20px;
    min-height: auto;
  }

  .notification-form form {
    padding: 25px;
  }

  .form-group select,
  .form-group textarea {
    padding: 10px 12px;
    font-size: 14px;
  }

  .submit-button {
    padding: 12px 24px;
    font-size: 15px;
  }
}

/* Add placeholder styling */
.form-group select option {
  color: #2c3e50;
  padding: 10px;
}

.form-group textarea::placeholder {
  color: #a0aec0;
}

/* Add focus styles for accessibility */
.form-group select:focus-visible,
.form-group textarea:focus-visible {
  outline: 2px solid #3498db;
  outline-offset: 1px;
}

/* Style for disabled state */
.form-group select:disabled,
.form-group textarea:disabled,
.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.error-message {
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  color: #dc2626;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 14px;
}
