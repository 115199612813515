.language-select-container {
  position: relative;
  width: 100%;
}

.language-select-button {
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.dropdown-arrow {
  font-size: 12px;
  color: #666;
  margin-left: 4px;
}

.language-select-button:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

.language-select-dropdown {
  position: absolute;
  z-index: 1000;
  width: 100%;
  margin-top: 4px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-container {
  padding: 8px;
}

.language-search-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.language-search-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

.language-options {
  max-height: 240px;
  overflow-y: auto;
}

.language-option {
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}

.language-option:hover {
  background-color: #f3f4f6;
}

.language-option.selected {
  background-color: #eff6ff;
  color: #2563eb;
}

/* Scrollbar styling */
.language-options::-webkit-scrollbar {
  width: 8px;
}

.language-options::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.language-options::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.language-options::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.search-container input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background: white;
}

.search-container input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

/* Add this to prevent KeymanWeb from interfering with search input */
.no-kmw {
  font-family: system-ui, -apple-system, sans-serif !important;
}

/* Ensure search input styling remains consistent */
.language-search-input.no-kmw {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background: white;
}

.language-search-input.no-kmw:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}
