.notification-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 12px;
  padding: 0;
  max-width: 500px;
  width: 90%;
  outline: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.notification-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.notification-modal-content {
  padding: 30px;
}

.notification-modal-title {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 20px 0;
  color: #2c3e50;
}

.notification-modal-message {
  margin-bottom: 25px;
}

.notification-modal-message .user-count {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #2c3e50;
}

.message-preview {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin: 15px 0;
}

.message-preview h4 {
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 10px 0;
  color: #2c3e50;
}

.message-preview p {
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 15px 0;
  color: #4a5568;
}

.message-preview p:last-child {
  margin-bottom: 0;
}

.confirmation-text {
  font-size: 15px;
  font-weight: 500;
  margin: 20px 0 0 0;
  color: #2c3e50;
}

.notification-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 25px;
}

.modal-button {
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
}

.modal-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.cancel-button {
  background-color: #e2e8f0;
  color: #4a5568;
}

.cancel-button:hover:not(:disabled) {
  background-color: #cbd5e0;
}

.confirm-button {
  background-color: #3498db;
  color: white;
}

.confirm-button:hover:not(:disabled) {
  background-color: #2980b9;
}

/* Responsive styles */
@media (max-width: 768px) {
  .notification-modal-content {
    padding: 20px;
  }

  .notification-modal-title {
    font-size: 20px;
  }

  .modal-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
