/* ConversationTopics Component Styles */

.convo-topics-panel {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.convo-topic-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100%;
  overflow-y: auto;
}

.convo-topic-question {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.convo-saved-topic {
  margin-bottom: 16px;
  padding: 12px;
  background-color: #f1f8e9;
  border-radius: 4px;
  border-left: 3px solid #4caf50;
}

.convo-topic-value {
  font-size: 14px;
  color: #2e7d32;
}

.convo-topic-search {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}

.convo-topic-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  height: 42px;
}

.convo-topic-input:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.convo-topic-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.convo-topic-suggestion {
  padding: 10px 12px;
  cursor: pointer;
  font-size: 14px;
  border-bottom: 1px solid #f0f0f0;
}

.convo-topic-suggestion:last-child {
  border-bottom: none;
}

.convo-topic-suggestion:hover {
  background-color: #f5f5f5;
}

.convo-topic-no-results {
  padding: 12px;
  margin-top: 8px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.convo-topic-no-results p {
  margin: 0;
  color: #666;
}

.convo-topic-save-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
}

.convo-topic-save-button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.convo-topic-save-button:hover:not(:disabled) {
  background-color: #388e3c;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.convo-topic-save-button:active:not(:disabled) {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.convo-topic-save-button:disabled {
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
  box-shadow: none;
}

.convo-topic-error {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 8px 12px;
  border-radius: 4px;
  margin-top: 8px;
  font-size: 13px;
  width: 100%;
  box-sizing: border-box;
}

.convo-topic-success {
  color: #2e7d32;
  background-color: #e8f5e9;
  padding: 8px 12px;
  border-radius: 4px;
  margin-top: 8px;
  font-size: 13px;
  width: 100%;
  box-sizing: border-box;
}

.convo-no-topics,
.convo-no-topic-fields {
  color: #666;
  font-size: 14px;
  padding: 16px;
  text-align: center;
  font-style: italic;
}

.convo-topics-error {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 12px;
  border-radius: 4px;
  margin: 16px;
  font-size: 14px;
}
