/* src/index.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* // new filter */

.cs-video-filters {
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  transition: all 0.3s linear;
  border: 1px solid #ccc;
  padding: 4px 10px;
  border-radius: 5px;
  margin: 6px 0 12px 0;
}

.cs-video-filters-control {
  height: auto !important;
}

.cs-video-filters-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
}

.form-group {
  margin: 3px 0;

  label {
    font-size: 12px;
    margin-bottom: 5px !important;
    color: #666;
  }

}
