.login-page {
  display: flex;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.login-left-panel {
  width: 35%;
  background: linear-gradient(135deg, #56ab91 0%, #7bc393 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.left-content {
  text-align: center;
}

.logo-container {
  width: 100px;
  height: 100px;
  margin: 0 auto 1.5rem;
  background: white;
  border-radius: 50%;
  padding: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.left-content h1 {
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.login-right-panel {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: white;
}

.login-form-container {
  width: 100%;
  max-width: 450px;
  padding: 0 3rem;
}

.form-group {
  margin-bottom: 1.75rem;
  width: 100%;
}

.form-group label {
  display: block;
  color: #333;
  font-size: 14px;
  margin-bottom: 8px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f5f8fa;
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-group input:focus {
  outline: none;
  border-color: #56ab91;
  box-shadow: 0 0 0 2px rgba(86, 171, 145, 0.1);
}

.form-group input::placeholder {
  color: #999;
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: #56ab91;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 1.25rem;
  transition: background-color 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.login-button:hover {
  background-color: #4a9780;
}

.reset-password {
  text-align: center;
}

.reset-password a {
  color: #56ab91;
  font-size: 13px;
  text-decoration: none;
}

.reset-password a:hover {
  text-decoration: underline;
}

/* Enhanced Responsive Design */
@media (max-width: 1024px) {
  .login-left-panel {
    width: 40%;
  }

  .login-right-panel {
    width: 60%;
  }

  .login-form-container {
    padding: 0 2rem;
  }
}

@media (max-width: 768px) {
  .login-page {
    flex-direction: column;
    height: 100vh;
  }

  .login-left-panel {
    width: 100%;
    padding: 1.5rem 1rem;
    height: 30vh;
    min-height: auto;
  }

  .login-right-panel {
    width: 100%;
    height: 70vh;
    padding: 1rem;
    overflow-y: auto;
  }

  .logo-container {
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;
  }

  .left-content h1 {
    font-size: 18px;
  }

  .login-form-container {
    padding: 0 1.5rem;
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
  }

  .form-group {
    margin-bottom: 1.25rem;
  }

  .form-group input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    height: 48px;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
  }

  .login-button {
    width: 100%;
    height: 48px;
    margin-bottom: 1rem;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .login-left-panel {
    height: 25vh;
    padding: 1rem;
  }

  .login-right-panel {
    height: 75vh;
    padding: 1rem 0;
  }

  .logo-container {
    width: 70px;
    height: 70px;
    padding: 8px;
  }

  .login-form-container {
    padding: 0 1rem;
    width: 100%;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-group input {
    font-size: 16px;
    height: 44px;
    padding: 10px 12px;
  }
}

/* Handle viewport height issues on mobile browsers */
@media screen and (max-height: 600px) {
  .login-left-panel {
    height: 120px;
    padding: 0.5rem;
  }

  .login-right-panel {
    height: calc(100vh - 120px);
  }

  .logo-container {
    width: 60px;
    height: 60px;
    margin-bottom: 0.5rem;
  }

  .form-group {
    margin-bottom: 0.75rem;
  }
}

/* Fix iOS input zoom */
@supports (-webkit-touch-callout: none) {
  .form-group input {
    font-size: 16px !important;
  }
}

/* Ensure proper box sizing everywhere */
*,
*:before,
*:after {
  box-sizing: border-box;
}

/* Prevent form element margins in Safari */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .form-group input {
      margin: 0;
    }
  }
}
