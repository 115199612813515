.sidebar {
  width: 250px;
  height: calc(100vh - 50px);
  background-color: #ffffff;
  position: fixed;
  top: 50px;
  left: -250px;
  overflow-x: hidden;
  transition: left 0.3s ease;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar.open {
  left: 0;
}

.sidebar-menu {
  padding: 0;
}

.menu-item {
  margin-bottom: 0;
  border-bottom: 1px solid #e0e0e0;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;
  color: #424242;
  transition: all 0.3s ease;
}

.menu-header:hover {
  background-color: #f5f5f5;
  padding-left: 20px;
}

.arrow {
  transition: transform 0.3s ease;
  color: #757575;
}

.arrow.down {
  transform: rotate(90deg);
}

.submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: #5887f9;
}

.submenu.open {
  max-height: 500px;
}

.submenu-item {
  padding: 10px 16px 10px 32px;
  cursor: pointer;
  color: #fff;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* Style for different nesting levels */
.depth-0 > .menu-header {
  background-color: #ffffff;
  color: #424242;
}

.depth-1 > .menu-header {
  padding-left: 32px;
  background-color: #4a78e0;
  color: #fff;
}

.depth-1 > .submenu {
  background-color: #3a68d0;
}

.depth-1 .submenu-item {
  padding-left: 48px;
}

.depth-2 > .menu-header {
  padding-left: 48px;
  background-color: #2a58c0;
}

.depth-2 > .submenu {
  background-color: #1a48b0;
}

.depth-2 .submenu-item {
  padding-left: 64px;
}

/* Remove border from last submenu item in each submenu */
.submenu > .submenu-item:last-child,
.submenu > .menu-item:last-child {
  border-bottom: none;
}

.submenu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  padding-left: 40px;
}

.depth-1 .submenu-item:hover {
  padding-left: 56px;
}

.depth-2 .submenu-item:hover {
  padding-left: 72px;
}

/* Add hover animation - slide in from left */
.submenu-item::before {
  content: "";
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  transition: left 0.3s ease;
}

.submenu-item:hover::before {
  left: 0;
}

.menu-item:last-child {
  border-bottom: none;
}

.depth-0 > .menu-header:hover .arrow {
  transform: scale(1.2);
}

.depth-0 > .menu-header:hover .arrow.down {
  transform: rotate(90deg) scale(1.2);
}

/* Ensure nested menu items have the right styling */
.depth-1 > .menu-header:hover,
.depth-2 > .menu-header:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.depth-1 > .menu-header:hover .arrow,
.depth-2 > .menu-header:hover .arrow {
  color: #fff;
}
