body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mg-timeline-audio {
  padding: 90px 40px;
  margin: 0 -15px;
}
.audio-el {
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 50px 1fr 40px;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 8px; }
  .audio-el button {
    border: none;
    background-color: transparent; }
  .audio-el .fa {
    font-size: 25px; }
  .audio-el-progress {
    position: relative;
    height: 8px; }
    .audio-el-progress-track, .audio-el-progress-slider {
      height: 8px;
      position: absolute;
      z-index: 1;
      border: 1px solid #ccc;
      width: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
      border-radius: 0 5px 5px 0; }
    .audio-el-progress-slider {
      transition: width .1s linear;
      z-index: 2;
      background-color: #9fbc59; }
    .audio-el-progress-timing {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      width: 100%;
      top: 15px;
      font-size: 14px; }
  .audio-el-volume {
    position: relative; }
    .audio-el-volume-slider {
      position: absolute;
      display: none;
      background-color: white;
      width: 100px;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 5px;
      bottom: 220%;
      left: 50%;
      padding: 2px 5px;
      cursor: pointer;
      transform: translateX(-50%) rotate(270deg); }
      .audio-el-volume-slider:hover {
        display: block; }
      .audio-el-volume-slider::after {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        background-color: white;
        z-index: -14;
        border: 1px solid #ccc;
        top: 8px;
        left: -8px;
        transform: rotate(45deg);
        border-top: none;
        border-right: none; }

.audio-el-volume-btn:hover + .audio-el-volume-slider {
  display: block; }

  
.theme-btn {
  border: 1px solid #9FBC59;
  background-color: #9FBC59;
  padding: 10px 15px;
  border-radius: 4px 4px;
  color: #FFF;
}

.theme-btn:hover {
  background-color: #FFFFFF;
  color: #1E1E1E;
}

.theme-btn--inverse {
  background-color: #FFFFFF;
  color: #1E1E1E;
}

.theme-btn--inverse:hover {
  background-color: #9FBC59;
  color: #FFF;
}

.theme-btn--highlighted {
  background-color: #178cc6;
  border: 1px solid #178cc6;
  color: #FFF;
}

.theme-btn--highlighted:hover {
  background: #FFFFFF;
  color: #1E1E1E;
}

.theme-btn--small {
  padding: 5px 10px;
  font-size: 14px;
}

.theme-btn--small-disabled {
  padding: 5px 10px;
  font-size: 14px;
  cursor: not-allowed;
  background: #F4F4F4;
  color: #ccc;
  border: 1px solid #ccc;
}

.theme-btn--small-disabled:hover {
  background: #F4F4F4;
  color: #ccc;
  border: 1px solid #ccc;
}

.theme-btn--positive-green {
  background-color: forestgreen;
  border: 1px solid forestgreen;
  color: #FFF;
}

.theme-btn--positive-green:hover {
  background: #FFFFFF;
  color: #1E1E1E;
}

.theme-btn--center {
  display: block;
  margin: 0 auto;
  padding: 5px 5px;
}

.theme-btn--extra-small {
  padding: 5px 8px;
  font-size: 14px;
}

.theme-btn--xxs {
  padding: 5px 12px;
  font-size: 12px;
}

.theme-btn--full-width {
  width: 100%;
}

.theme-btn--half-width {
  width: 50%;
}

.theme-btn {
  transition: all 200ms;
}

.theme-btn a {
  color: inherit;
  text-decoration: none;
}

.theme-btn--danger {
  background-color: crimson;
  border: 1px solid crimson;
  color: #FFF;
  border-radius: 4px 4px;
}

.theme-btn--danger:visited {
  background: #FFFFFF;
  color: #1E1E1E;
}

.theme-btn--danger:hover {
  background: #FFFFFF;
  color: #1E1E1E;
}

.theme-btn--danger:active {
  background: #FFFFFF;
  color: #1E1E1E;
}

.theme-btn--text {
  background-color: transparent;
  border: none;
  color: #9FBC59;
}

.theme-btn--text:hover {
  color: white;
  background-color: #9fbc5a;
}

/* css for pdf viewer */
.rpv-core__minimal-button[aria-label="Download"] {
  display: none !important;
}
.rpv-core__minimal-button[aria-label="Print"] {
  display: none !important;
}
.rpv-core__minimal-button[aria-label="Open file"] {
  display: none !important;
}
.rpv-core__minimal-button[aria-label="More actions"] {
  display: none !important;
}
.rpv-core__minimal-button[aria-label="Print"] {
  display: none !important;
}
.rpv-default-layout__sidebar-headers {
  display: none !important;
}
.rpv-toolbar__left {
  display: none !important;
}
