/* ConversationWordTagger.css - Complete file */

.word-tagger-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.word-tagger-flex-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.word-tagger-column {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.word-tagger-left-column {
  flex: 1;
  padding-right: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.word-tagger-right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.word-tagger-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 8px;
}

.word-tagger-section-title {
  font-size: 14px;
  color: #333;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.word-tagger-section-subtitle {
  font-size: 11px;
  color: #777;
}

/* Word tag highlighting in conversation */
.word-tag-highlight {
  background-color: #fff2cc;
  border-radius: 2px;
  padding: 1px 2px;
  position: relative;
  cursor: default;
  display: inline;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.word-tag-highlight:hover {
  background-color: #ffe066;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.word-tag-highlight::after {
  content: attr(data-tag);
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  font-size: 11px;
  padding: 3px 8px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  white-space: nowrap;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.word-tag-highlight:hover::after {
  opacity: 1;
}

Improved selection styles ::selection {
  background-color: #bbdefb;
  color: #333;
}

/* Selected text display at top of word tagger */
.word-tagger-selected {
  background-color: #f1f8e9;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 12px;
  border-left: 3px solid #4caf50;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.word-tagger-selected-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.word-tagger-selected-label {
  font-weight: 600;
  color: #333;
  font-size: 13px;
}

.word-tagger-clear-button {
  background-color: transparent;
  color: #999;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s;
}

.word-tagger-clear-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #666;
}

.word-tagger-selected-content {
  padding: 8px 10px;
  background-color: white;
  border-radius: 4px;
  font-size: 13px;
  color: #333;
  line-height: 1.4;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
  word-break: break-word;
}

/* No selection message */
.word-tagger-no-selection {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
  background-color: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 12px;
  border: 1px dashed #ddd;
}

/* Tag input form */
.word-tagger-form {
  background-color: #f3f4f6;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

/* Tag input container */
.word-tagger-tag-input-container {
  display: flex;
  margin-bottom: 8px;
}

.word-tagger-tag-input {
  flex: 1;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  font-size: 13px;
  transition: all 0.2s;
}

.word-tagger-tag-input:focus {
  outline: none;
  border-color: #2196f3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
}

.word-tagger-apply-button {
  padding: 8px 16px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.word-tagger-apply-button:hover:not(:disabled) {
  background-color: #1976d2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.word-tagger-apply-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Enhanced suggestions dropdown */
.word-tagger-suggestions {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 50;
}

.word-tagger-suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 13px;
  border-bottom: 1px solid #f1f1f1;
  transition: all 0.1s;
}

.word-tagger-suggestion-item:last-child {
  border-bottom: none;
}

.word-tagger-suggestion-item:hover {
  background-color: #e3f2fd;
}

.word-tagger-no-suggestions {
  padding: 8px 12px;
  font-size: 13px;
  color: #666;
  text-align: center;
}

/* Error and success messages */
.word-tagger-error {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.word-tagger-error.within-section {
  margin: 8px;
}

.word-tagger-dismiss-error {
  background: none;
  border: none;
  color: #d32f2f;
  cursor: pointer;
  font-size: 12px;
  padding: 0;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.word-tagger-success {
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 10px;
}

/* Improved pending tags section */
.word-tagger-pending-tags {
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 10px;
  overflow: hidden;
}

.word-tagger-pending-content {
  flex: 1;
  overflow-y: auto;
  padding: 0 8px 8px 8px;
}

.word-tagger-save-all-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 12px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
}

.word-tagger-save-all-button:hover:not(:disabled) {
  background-color: #388e3c;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.word-tagger-save-all-button:active:not(:disabled) {
  transform: translateY(1px);
  box-shadow: none;
}

.word-tagger-save-all-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.word-tagger-pending-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.word-tagger-pending-item {
  display: flex;
  align-items: center;
  background-color: #f1f8e9;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 12px;
  border-left: 3px solid #8bc34a;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}

.word-tagger-pending-item:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.word-tagger-pending-text {
  flex: 1;
  font-weight: 600;
  margin-right: 8px;
  font-size: 12px;
  word-break: break-word;
}

.word-tagger-pending-tag {
  background-color: #c8e6c9;
  padding: 2px 8px;
  border-radius: 12px;
  color: #1b5e20;
  margin-right: 8px;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 500;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.word-tagger-remove-pending {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 12px;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  transition: all 0.2s;
}

.word-tagger-remove-pending:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #d32f2f;
}

/* Enhanced existing tags section */
.word-tagger-existing-tags {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.word-tagger-existing-content {
  flex: 1;
  overflow-y: auto;
  padding: 0 8px 8px 8px;
}

.word-tagger-empty-message {
  text-align: center;
  padding: 15px;
  color: #888;
  font-size: 13px;
  font-style: italic;
}

.word-tagger-loading {
  text-align: center;
  padding: 15px;
  color: #666;
  font-size: 13px;
}

.word-tagger-tag-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.word-tagger-tag-item {
  padding: 10px;
  border-radius: 4px;
  background-color: #f9f9f9;
  border-left: 3px solid #2196f3;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
  margin-bottom: 8px;
}

.word-tagger-tag-item:hover {
  background-color: #f3f8fd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.word-tagger-tagged-text {
  font-weight: 600;
  color: #333;
  margin-bottom: 6px;
  font-size: 13px;
  word-break: break-word;
  line-height: 1.4;
}

.word-tagger-tag-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.word-tagger-tag-badge {
  display: flex;
  align-items: center;
  background-color: #e3f2fd;
  border-radius: 12px;
  padding: 3px 10px;
  font-size: 11px;
  color: #1976d2;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
  transition: all 0.2s;
}

.word-tagger-tag-badge:hover {
  background-color: #bbdefb;
}

.word-tagger-tag-name {
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.word-tagger-remove-tag {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 10px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: all 0.2s;
}

.word-tagger-remove-tag:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #d32f2f;
}

/* Improve visibility of the tagger section */
/* .convo-word-tagger-panel {
  border-left: 3px solid #2196f3 !important;
} */

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .word-tagger-flex-container {
    flex-direction: column;
  }

  .word-tagger-left-column,
  .word-tagger-right-column {
    width: 100%;
    padding-right: 0;
  }

  .word-tagger-left-column {
    height: 50%;
    margin-bottom: 8px;
  }

  .word-tagger-right-column {
    height: 50%;
  }

  .word-tagger-existing-tags {
    flex: 1;
  }
}
