/* ConversationTags Component Styles */

/* Tags panel styles */
.convo-tags-panel {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.convo-existing-tags,
.convo-tag-fields {
  display: flex;
  flex-direction: column;
  overflow: visible; /* Changed from hidden to visible */
}

.convo-existing-tags {
  max-height: 120px; /* Changed from 30% to a fixed 120px */
  min-height: 50px; /* Reduced from 100px */
  border-bottom: 1px solid #eee;
  flex-shrink: 0; /* Prevent this section from shrinking */
  margin-bottom: 8px;
}

.convo-tag-fields {
  flex: 1;
  overflow: hidden;
}

.convo-tags-container {
  padding: 5px 10px; /* Reduced padding */
  overflow-y: auto; /* Enable vertical scrolling */
  height: 100%; /* Fill available space */
  max-height: 100%; /* Ensure it doesn't exceed parent */
}

.convo-tags-list,
.convo-tag-fields-form {
  padding: 5px; /* Reduced padding */
  overflow-y: auto;
  flex: 1;
}

.convo-tag-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 8px;
}

.convo-tag-pill {
  background-color: #e3f2fd;
  border: 1px solid #90caf9;
  color: #1565c0;
  padding: 3px 7px; /* Slightly reduced padding */
  border-radius: 16px;
  font-size: 11px; /* Reduced font size */
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.convo-tag-item {
  padding: 4px 6px; /* Reduced padding */
  margin-bottom: 4px; /* Reduced margin */
  background-color: #f1f8e9;
  border-radius: 4px;
  border-left: 2px solid #4caf50;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.convo-tag-field {
  font-weight: 600;
  color: #333;
  font-size: 11px; /* Reduced font size */
}

.convo-tag-value {
  color: #444;
  font-size: 11px; /* Reduced font size */
}

.convo-tag-name {
  color: #666;
  font-size: 10px; /* Reduced font size */
  font-style: italic;
}

.convo-saved-tags {
  margin-top: 8px; /* Reduced margin */
  border-top: 1px dashed #ddd;
  padding-top: 6px; /* Reduced padding */
}

.convo-subsection-title {
  font-size: 12px; /* Smaller section title */
  margin: 4px 0;
  color: #424242;
}

.convo-no-tags,
.convo-no-tag-fields {
  color: #666;
  font-size: 13px;
  padding: 8px;
  text-align: center;
  font-style: italic;
}

.convo-tags-error,
.convo-tag-error {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 8px;
  border-radius: 4px;
  margin: 8px;
  font-size: 12px;
}

.convo-tag-success {
  color: #2e7d32;
  background-color: #e8f5e9;
  padding: 8px;
  border-radius: 4px;
  margin: 8px;
  font-size: 12px;
}

/* Tag field input styles */
.convo-tag-field-item {
  margin-bottom: 12px;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 10px;
  transition: all 0.2s ease;
}

.convo-tag-field-item:hover {
  border-color: #ddd;
  background-color: #fafafa;
}

.convo-tag-field-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.convo-tag-field-label {
  font-weight: 600;
  font-size: 13px;
  color: #333;
}

.convo-required {
  color: #f44336;
  margin-left: 2px;
}

.convo-tag-mode-indicator {
  font-size: 11px;
  color: #666;
  font-style: italic;
}

.convo-tag-toggle-button {
  font-size: 11px;
  padding: 3px 8px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  color: #666;
  transition: all 0.2s ease;
}

.convo-tag-toggle-button:hover {
  background-color: #e0e0e0;
  color: #333;
}

.convo-tag-toggle-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.convo-tag-input-container {
  position: relative;
  width: 100%;
}

.convo-tag-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
  box-sizing: border-box;
  height: 36px;
}

.convo-tag-input:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.convo-tag-search {
  position: relative;
  width: 100%;
}

.convo-tag-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

.convo-tag-suggestion {
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
}

.convo-tag-suggestion:hover {
  background-color: #f0f0f0;
}

.convo-tag-save-container {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.convo-tag-save-button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  font-size: 13px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.convo-tag-save-button:hover:not(:disabled) {
  background-color: #388e3c;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.convo-tag-save-button:active:not(:disabled) {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.convo-tag-save-button:disabled {
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
  box-shadow: none;
}

/* Radio button group styles */
.convo-tag-radio-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 5px 0;
}

/* Horizontal radio button layout */
.convo-tag-radio-group.horizontal {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}

.convo-tag-radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
  user-select: none;
}

.horizontal .convo-tag-radio-label {
  margin-right: 4px;
}

.convo-tag-radio-label:hover {
  background-color: #f5f5f5;
}

.convo-tag-radio {
  margin: 0;
  cursor: pointer;
}

.convo-tag-radio-text {
  font-size: 13px;
  color: #333;
}

/* Dropdown styles */
.convo-tag-dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.convo-tag-dropdown {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 13px;
  color: #333;
  cursor: pointer;
  height: 36px;
}

.convo-tag-dropdown:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.convo-tag-field-with-toggle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

/* Selected value display */
.convo-selected-value {
  margin-top: 8px;
  padding: 6px 10px;
  background-color: #e8f5e9;
  border-left: 3px solid #4caf50;
  border-radius: 3px;
  font-size: 12px;
  color: #1b5e20;
}

.mt-2 {
  margin-top: 8px;
}

/* Add these styles to ConversationTags.css */

.convo-tag-no-results {
  padding: 10px;
  margin-top: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.convo-tag-no-results p {
  margin: 0 0 8px 0;
  color: #666;
}

.convo-tag-toggle-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.convo-tag-toggle-button:hover {
  background-color: #e0e0e0;
}

/* Section title styling */
.convo-section-title {
  font-size: 14px;
  margin: 0;
  padding: 8px 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  color: #424242;
  font-weight: 500;
}
