.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  height: 100vh;
  box-sizing: border-box;
}

/* Main content section */
.main-content {
  display: flex;
  gap: 20px;
  height: 40%;
}

.text-container {
  flex: 2;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
}

.info-container {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 0; /* This is crucial for nested flex scrolling */
}

.header {
  margin: 0 0 15px 0;
  font-size: 18px;
  font-weight: bold;
}

.original-text {
  font-size: 16px;
  line-height: 1.5;
  overflow-y: auto;
  height: calc(100% - 40px);
}

.task-info {
  font-size: 14px;
  line-height: 1.6;
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; /* Important for nested flex scrolling */
  padding-right: 10px;
}

.task-info p {
  margin: 8px 0;
}

/* Translation container and input */
.translation-container {
  height: 40%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.translation-input {
  width: 90%;
  height: calc(100% - 60px);
  padding: 12px 40px 12px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  font-size: 16px;
  font-family: inherit;
  line-height: 1.5;
  background-color: #fafafa;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.translation-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.15);
  background-color: #fff;
}

.translation-input::placeholder {
  color: #999;
  font-style: italic;
}

/* Keyboard button styling */
.kmw-keyboard-icon,
.custom-keyboard-button {
  position: absolute !important;
  right: 25px !important;
  bottom: 25px !important;
  top: auto !important;
  z-index: 10 !important;
  background-color: #fff !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  padding: 4px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
  width: 24px !important;
  height: 24px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.kmw-keyboard-icon:hover,
.custom-keyboard-button:hover {
  background-color: #f8f9fa !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15) !important;
}

/* Navigation buttons */
.navigation {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: opacity 0.2s, transform 0.1s;
}

.button:hover:not(:disabled) {
  transform: translateY(-1px);
}

.button:active:not(:disabled) {
  transform: translateY(0);
}

.previous-button,
.next-button {
  background-color: #007bff; /* Using the same blue as other buttons */
  color: white;
}

.submit-button {
  background-color: #28a745;
  color: white;
  max-width: 200px;
}

.button.disabled,
.button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Language selector styling */
.language-selector {
  margin-bottom: 15px;
  width: 300px;
  flex-shrink: 0;
}

.language-select-container {
  z-index: 100;
}

.language-select {
  width: 300px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 15px;
  padding-right: 45px;
}

.language-select:hover {
  border-color: #007bff;
}

.language-select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.language-select option {
  padding: 10px;
  font-size: 16px;
}

.language-select option:disabled {
  color: #999;
  font-style: italic;
}

.language-select::-ms-expand {
  display: none;
}

/* Status displays */
.loading,
.error,
.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
}

.error {
  color: red;
}

.pending-count {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #f8f9fa;
  border-radius: 6px;
  border: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.count {
  background-color: #007bff;
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  min-width: 28px;
  text-align: center;
  display: inline-block;
}

.counts-container {
  display: flex;
  gap: 20px;
  margin-top: auto; /* Push to bottom */
  padding: 12px;
  background: #f5f5f5;
  border-radius: 8px;
  flex-shrink: 0; /* Prevent shrinking */
  flex-wrap: nowrap; /* Prevent wrapping by default */
}

.count-item {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.count-label {
  font-weight: 600;
  color: #666;
}

.count-value {
  background: #fff;
  padding: 4px 12px;
  border-radius: 4px;
  font-weight: bold;
  color: #2c5282;
  border: 1px solid #e2e8f0;
  min-width: 40px;
  text-align: center;
}

.target-language-badge {
  display: inline-block;
  background-color: #e2e8f0;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  color: #2d3748;
  margin-left: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    height: auto;
  }

  .info-container {
    min-height: 300px; /* Ensure minimum height on mobile */
  }

  .text-container {
    width: 100%;
    height: auto;
    min-height: 200px;
  }

  .translation-container {
    height: 300px;
    min-height: 250px;
  }

  .language-selector {
    width: 100%;
    margin-bottom: 12px;
  }

  .language-select {
    width: 100%;
  }

  .kmw-keyboard-icon,
  .custom-keyboard-button {
    right: 15px !important;
    bottom: 15px !important;
    padding: 3px !important;
    width: 20px !important;
    height: 20px !important;
  }

  .translation-input {
    padding-right: 35px;
  }

  .pending-count {
    margin-top: 12px;
    padding: 8px 12px;
  }

  .count {
    padding: 3px 10px;
    font-size: 13px;
  }

  .task-info {
    max-height: 300px; /* Add max-height for mobile */
  }

  .counts-container {
    flex-wrap: wrap; /* Allow wrapping on mobile */
    justify-content: space-around;
    margin-top: 10px; /* Reduced margin on mobile */
    padding: 8px; /* Smaller padding on mobile */
  }

  .count-item {
    margin: 4px 0; /* Add some vertical spacing when wrapped */
  }
}
