.convo-dashboard-container {
  max-width: 100%;
  margin: 0;
  padding: 4px 4px 70px 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

/* Add to your existing CSS file */
.custom-keyboard-button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f5f5f5;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 100;
}

.convo-keyboard-language-selector {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 20px;
}

.kmw-keyboard-icon {
  margin-left: auto;
}

.convo-header {
  margin-bottom: 4px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}

.convo-title {
  color: #333;
  margin: 0;
  font-size: 18px;
  white-space: nowrap;
}

.convo-mapping-selector {
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
}

.convo-mapping-selector label {
  font-weight: 600;
  margin-right: 5px;
  color: #333;
  white-space: nowrap;
  font-size: 14px;
}

.convo-select {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #aaa;
  background-color: white;
  font-size: 14px;
  min-width: 250px;
  max-width: 100%;
  height: 32px;
  color: #333;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.convo-select:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.convo-select:hover {
  border-color: #666;
}

/* Full layout structure */
.convo-full-layout {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
  overflow: hidden;
}

/* Top section - 40% height */
.convo-top-section {
  height: 35%;
  margin-bottom: 8px;
}

/* Bottom section - 60% height */
.convo-bottom-section {
  height: 65%;
  display: grid;
  grid-template-columns: 0.8fr 1.4fr 0.8fr;
  gap: 8px;
  overflow: hidden;
}

/* Grid layout for the panel design */
.convo-grid-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  height: 100%;
  overflow: hidden;
}

.convo-panel {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.convo-panel-title {
  padding: 5px 8px;
  margin: 0;
  background-color: #f8f8f8;
  border-bottom: 1px solid #eaeaea;
  font-size: 14px;
  color: #333;
}

.convo-section-title {
  margin: 0;
  padding: 6px 8px;
  font-size: 13px;
  color: #444;
  border-bottom: 1px solid #eee;
  background-color: #f9f9f9;
}

.convo-subsection-title {
  font-size: 12px;
  color: #555;
  margin: 0 0 8px 0;
  font-weight: 600;
}

.convo-messages-container {
  padding: 5px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.convo-message {
  padding: 5px 8px;
  border-radius: 4px;
  background-color: #f9f9f9;
  border-left: 2px solid #ddd;
  position: relative;
  transition: all 0.2s ease;
  margin-bottom: 8px;
}

.convo-message:hover {
  background-color: #f0f0f0;
  border-left-color: #1976d2;
}

.convo-message-rectified {
  border-left-color: #4caf50;
  background-color: #f1f8e9;
}

.convo-message-rectified-label {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #4caf50;
  color: white;
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 500;
}

.convo-message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  flex-wrap: wrap;
  gap: 4px;
}

.convo-sender {
  font-weight: 600;
  color: #333;
}

.convo-timestamp {
  font-size: 12px;
  color: #666;
}

.convo-message-content {
  line-height: 1.5;
  color: #333;
  word-break: break-word;
  user-select: text;
  cursor: text;
  padding: 4px;
  border-radius: 3px;
  transition: background-color 0.2s;
}

.convo-message-content:hover {
  background-color: #f0f7ff;
}

.convo-selection-hint {
  font-size: 12px;
  color: #666;
  font-weight: normal;
  margin-left: 10px;
  font-style: italic;
}

/* Styles for the edit panel */
.convo-edit-panel {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.convo-edit-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 0;
}

.convo-input-fields-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 5px;
  overflow-y: auto;
  flex: 1;
}

/* Simplified input field without labels */
.convo-input-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 5px 8px;
  background-color: #f9f9f9;
  transition: all 0.2s ease-in-out;
  margin-bottom: 8px;
}

.convo-input-field-saved {
  border-left: 3px solid #4caf50;
  background-color: #f1f8e9;
}

.convo-input-wrapper {
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
}

.convo-edit-textarea {
  width: 100%;
  resize: vertical;
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: inherit;
  font-size: 13px;
  min-height: 40px;
  flex: 1;
  line-height: 1.4;
}

.convo-edit-textarea:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.convo-save-message-button {
  height: 32px;
  padding: 0 12px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  align-self: flex-start;
}

.convo-save-message-button:hover:not(:disabled) {
  background-color: #1976d2;
}

.convo-save-message-button.convo-saved {
  background-color: #4caf50;
}

.convo-save-message-button.convo-saved:hover:not(:disabled) {
  background-color: #388e3c;
}

.convo-save-message-button:disabled {
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
}

.convo-message-error {
  margin-top: 4px;
  padding: 4px 8px;
  background-color: #ffebee;
  color: #d32f2f;
  border-radius: 4px;
  font-size: 12px;
}

.convo-message-success {
  margin-top: 4px;
  padding: 4px 8px;
  background-color: #e8f5e9;
  color: #2e7d32;
  border-radius: 4px;
  font-size: 12px;
}

.convo-edit-error {
  padding: 10px;
  background-color: #ffebee;
  color: #d32f2f;
  border-radius: 4px;
  font-size: 14px;
  margin: 10px;
}

.convo-edit-success {
  padding: 10px;
  background-color: #e8f5e9;
  color: #2e7d32;
  border-radius: 4px;
  font-size: 14px;
  margin: 10px;
}

/* Make the input components match the size/style of the message components */
.message-0 {
  margin-top: 5px;
}

/* Navigation bar at bottom with centered button */
.convo-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #f8f8f8;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  height: 60px;
  max-width: 100%;
}

.convo-nav-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.convo-counter {
  font-size: 12px;
  color: #666;
  text-align: center;
}

.convo-nav-button {
  padding: 8px 12px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  width: 160px;
  text-align: center;
  font-size: 13px;
}

.convo-button-group {
  display: flex;
  gap: 8px;
}

.convo-commit-button {
  padding: 8px 16px;
  background-color: #ff5722; /* Orange color for commit */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  font-size: 14px;
}

.convo-commit-button:hover:not(:disabled) {
  background-color: #e64a19; /* Darker orange on hover */
}

.convo-nav-button:hover:not(:disabled) {
  background-color: #1565c0;
}

.convo-nav-button:disabled,
.convo-commit-button:disabled {
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
}

/* Loading and error states */
.convo-loading-container,
.convo-error-container,
.convo-no-data-container,
.convo-content-loading {
  text-align: center;
  padding: 10px;
  margin: 5px 0;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.convo-content-loading {
  padding: 60px;
}

.convo-loading-spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1976d2;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

.convo-loading-spinner.small {
  width: 20px;
  height: 20px;
  border-width: 2px;
  margin: 10px auto;
}

.convo-retry-button {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

/* Empty panel */
.convo-empty-panel {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.convo-selected-text-display {
  margin-top: 10px;
  padding: 10px;
  background-color: #f3f4f6;
  border-radius: 4px;
  border-left: 3px solid #4caf50;
}

.convo-selected-text-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.convo-selected-text-label {
  font-weight: 600;
  color: #333;
  font-size: 13px;
}

.convo-clear-selection {
  background-color: transparent;
  color: #999;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.convo-clear-selection:hover {
  background-color: #f1f1f1;
  color: #666;
}

.convo-selected-text-content {
  padding: 5px 8px;
  background-color: white;
  border-radius: 4px;
  font-size: 13px;
  color: #333;
  word-break: break-word;
}

/* Updated styles for word tagger section */
.convo-word-tagger-panel {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Styles for the virtual keyboard */
.keyboard-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.keyboard-toggle-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 12px;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.keyboard-toggle-btn:hover {
  background-color: #e9e9e9;
}

.keyboard-toggle-btn.active {
  background-color: #e3f2fd;
  border-color: #2196f3;
  color: #1976d2;
}

.keyboard-toggle-btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.keyboard-icon {
  font-size: 16px;
}

.keyboard-language-selector {
  min-width: 150px;
}

.keyboard-lang-select {
  width: 100%;
  padding: 6px 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
  font-size: 14px;
}

.keyboard-lang-select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

/* Custom Keyman Keyboard Button */
.custom-keyboard-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #2196f3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border: none;
  transition: all 0.2s;
}

.custom-keyboard-button:hover {
  background-color: #1976d2;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

/* Add a highlight to the active input */
.convo-edit-textarea.active-keyboard-input {
  border-color: #2196f3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.25);
}

/* Active language indicator */
.active-language-indicator {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #e3f2fd;
  color: #1976d2;
  font-size: 12px;
  margin-left: 8px;
}

/* Animation for spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive styles */
@media (max-width: 1024px) {
  .convo-full-layout {
    height: auto;
    overflow: visible;
  }

  .convo-top-section {
    height: 35%; /* Changed from 40% to 35% */
    margin-bottom: 8px; /* Increased from 4px to 8px for better separation */
  }

  .convo-grid-layout {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, minmax(250px, auto));
    height: auto;
    margin-bottom: 8px;
  }

  .convo-bottom-section {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, minmax(300px, auto));
    height: auto;
    margin-bottom: 60px;
  }

  .convo-input-wrapper {
    flex-direction: column;
    align-items: stretch;
  }

  .convo-save-message-button {
    margin-top: 5px;
    width: 100%;
  }

  .convo-navigation {
    flex-direction: column;
    gap: 16px;
    height: auto;
    padding: 16px;
  }

  .convo-nav-button {
    width: 100%;
    max-width: 300px;
  }

  .convo-nav-center {
    order: -1;
    margin-bottom: 8px;
  }
}

@media (max-width: 768px) {
  .convo-mapping-selector {
    flex-direction: column;
    align-items: flex-start;
  }

  .convo-select {
    width: 100%;
  }

  .convo-message-header {
    flex-direction: column;
    align-items: flex-start;
  }
}
