.profile-select-container {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #f7fafc;
}

.profile-heading {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.profile-dropdown {
  width: 100%;
  padding: 0.25rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  font-size: 0.875rem;
  cursor: pointer;
}

.profile-dropdown:focus {
  border-color: #4299e1;
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.profile-loading,
.profile-error {
  padding: 0.5rem;
  text-align: center;
  color: #4a5568;
  font-size: 0.875rem;
}

.profile-error {
  color: #e53e3e;
}

.selected-profiles {
  margin-top: 0.25rem;
}

.profile-label {
  display: block;
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.profile-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.profile-chip {
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.5rem;
  background-color: #edf2f7;
  border-radius: 9999px;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

.profile-chip:hover {
  background-color: #e2e8f0;
}

.chip-remove {
  margin-left: 0.5rem;
  border: none;
  background: none;
  padding: 0 0.25rem;
  cursor: pointer;
  font-size: 1.125rem;
  color: #4a5568;
  transition: color 0.2s;
  line-height: 1;
}

.chip-remove:hover {
  color: #e53e3e;
}
